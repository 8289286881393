'use client'

import { Container, Stack } from "@chakra-ui/react"
import { TRIAL_DAYS, useCanStartNewTrial } from "../../../../shared/hooks/pricing"

import { Headline } from "../../../../marketing/src/-components/Headline"
import PricingBillingInterval from "./BillingInterval"
import PricingCurrency from "./Currency"
import { isInMarketing } from "../../../../shared/helpers"

export const PricingHeader = ({ alwaysDisplayTable }) => {
    const canStartNewTrial = useCanStartNewTrial()
    return (
        <Container maxW='7xl' paddingInline="12px !important;">
            <Stack px={{ base: 2.5, md: 4 }} pt={{ base: 12, md: 12 }} pb={12} spacing={10}>
                {(!isInMarketing || !alwaysDisplayTable) && (
                    <Headline
                        heading="Plans & Pricing"
                        subheading={`Trusted by agencies and teams for website projects large and small. ${canStartNewTrial ? `Try us for free for ${TRIAL_DAYS} days` : 'Explore which plan is best for you'}.`}
                        maxW="lg"
                        spacing={{ base: 6, lg: 10 }}
                        textAlign="start"
                        mb={{ base: 3, md: 16 }}
                    />
                )}
                <Stack justifyContent="space-between" align="center" direction={{ base: "column", sm: "row" }} spacing={{ base: "2", md: "3" }}>
                    <PricingBillingInterval />
                    <PricingCurrency />
                </Stack>
            </Stack>
        </Container>
    )
}

export const motionProps = {
    // Configure the initial state pre-transition (optional)
    initial: {
        visibility: "hidden",
        opacity: 0,
        scale: 1,
    },
    variants: {
        enter: {
            visibility: "visible",
            opacity: 1,
            scale: 1,
            transition: {
                // If you want to completely get rid of the animation, just change this duration to 0
                duration: 0, // 0.3,
                ease: [0.4, 0, 0.2, 1],
            },
        },
        exit: {
            transitionEnd: {
                visibility: "hidden",
            },
            opacity: 0,
            scale: 1,
            transition: {
                // Same with this duration on exit animation
                duration: 0, // 0.1,
                easings: "easeOut",
            },
        },
    },
}