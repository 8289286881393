import {
    Badge,
    Box,
    Button,
    HStack,
    Icon,
    LightMode,
    List,
    ListItem,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react'
import { TRIAL_DAYS, useCanStartNewTrial, useGetMonthlyPlanPrice, useGetPriceWithCurrencySymbol, useShowAnnualBilling, useShowCurrencyForDollarSymbol } from '../../../../shared/hooks/pricing'
import { isInMarketing, toTitleCase } from '../../../../shared/helpers'
import { useEffect, useState } from 'react'
import { useGetAnnualPlanPrice, useGetShortPlanId } from "../../../../shared/hooks"

import { BsArrowRight } from 'react-icons/bs'
import { HiCheck } from 'react-icons/hi'
import { LabelPopover } from './LabelPopover'
import Link from 'next/link'

export const PricingCard = (props) => {

    const [isClient, setIsClient] = useState(false)
    useEffect(() => { setIsClient(true) }, [])

    const {
        product,
        data,
        description,
        label,
        discount,
        price,
        displayPrice,
        currency,
        popular,
        cta,
        ...rest
    } = props

    const { name } = product

    const showAnnualBilling = useShowAnnualBilling()

    const monthlyPrice = useGetMonthlyPlanPrice({ planId: product.id })
    const annualPrice = useGetAnnualPlanPrice(monthlyPrice)

    const priceWithSymbol = useGetPriceWithCurrencySymbol(showAnnualBilling ? Math.round((annualPrice / 12) * 100) / 100 : monthlyPrice)
    const currencyForDollarSymbol = useShowCurrencyForDollarSymbol()

    const planId = useGetShortPlanId()
    const canStartNewTrial = useCanStartNewTrial()

    const isUserPlan = planId === product.id

    const isFree = name === 'Free'
    const isPro = name === 'Professional'
    const isEnterprise = name === 'Enterprise'

    return (
        <LightMode>
            <Box
                width='full'
                height='full'
                maxWidth={{ base: 'full', md: 'md' }}
                marginX='auto'
                px={10}
                py={8}
                rounded='xl'
                position='relative'
                borderWidth={popular ? '2px' : '1px'}
                {...rest}
            >
                <Stack spacing='6'>
                    <Badge pos="absolute" py={0} rounded="xl" top={-2.5} fontWeight="medium" color="fg.default" fontSize="sm" textTransform="initial" colorScheme="green">{product?.badge}</Badge>
                    <VStack align="start" spacing={8}>
                        <VStack align="start" spacing={3} color="fg.default">
                            <Text fontSize="2xl" fontWeight="semibold">{name}</Text>
                            <Text fontSize="sm">{label}</Text>
                        </VStack>
                        <VStack align="start" spacing={0}>
                            <Text color="fg.muted" fontSize="sm">
                                Starting from
                            </Text>
                            <HStack color="fg.default">
                                <Text fontSize="3xl" fontWeight="medium">
                                    {isClient ? priceWithSymbol : '$0'}
                                </Text>
                                <Text fontSize="md" mt={1.5} fontWeight="semibold">
                                    {`${isClient ? currencyForDollarSymbol || '' : 'USD'} / mo`}
                                </Text>
                            </HStack>
                            <Text
                                visibility={isClient ? "visible" : "hidden"}
                                color="fg.default"
                                fontSize="sm"
                            >
                                {!isClient || isFree
                                    ?
                                    'Free forever'
                                    :
                                    `Billed ${showAnnualBilling ? 'annually' : 'monthly'} for ${isEnterprise ? `the first ${5} people` : "one person"}`
                                }
                            </Text>
                        </VStack>
                    </VStack>

                    <Button
                        minHeight='3rem'
                        borderRadius="lg"
                        fontWeight='semibold'
                        colorScheme={!isClient || !isFree ? 'blue' : "gray"}
                        width="full"
                        fontSize="md"
                        rightIcon={isClient && isInMarketing ? <BsArrowRight /> : null}
                        onClick={async () => {
                            if (!process.env.isNext) { // HAS TO BE THIS, not isInMarketing
                                const { store } = await import('../../../../src/store/');
                                const { toggleUpgradeModal } = await import('../../../../src/store/actions/ui-actions')
                                store.dispatch(toggleUpgradeModal({ showing: true, planId: product.id, screen: 'plans-pricing' }))
                            } else {
                                const router = await import('next/router')
                                router.push(`/app/${isFree ? 'signup' : `upgrade-modal?planId=${toTitleCase(product.id)}`}`)
                            }
                        }}
                    >
                        {isInMarketing
                            ? (
                                (canStartNewTrial && !isFree)
                                    ? `Try free for ${TRIAL_DAYS} days`
                                    : 'Get started'
                            )
                            : isUserPlan
                                ? 'Your current plan'
                                : canStartNewTrial
                                    ? `Try ${toTitleCase(product?.name)} for free`
                                    : `Upgrade`}
                    </Button>

                    <Text
                        fontWeight="medium"
                        color="fg.default"
                        fontSize="sm"
                    >
                        {`${!isClient || isFree ? "Free plan features" : `Everything in ${isPro ? 'Free' : 'Professional'}, plus`}:`}
                    </Text>
                    <List stylePosition='outside' spacing='4'>
                        {data?.map((feature, index) => (
                            <ListItem
                                key={index}
                                display='flex'
                                alignItems='center'
                                gap='4'
                            >
                                {feature?.label && (
                                    <Icon
                                        as={HiCheck}
                                        fontSize='xl'
                                        color={!isClient || isFree ? 'fg.subtle' : 'rarchy-green'}
                                    />
                                )}
                                <LabelPopover feature={feature} />
                            </ListItem>
                        ))}
                    </List>
                </Stack>
            </Box>
        </LightMode>
    )
}